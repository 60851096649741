import { useCallback, useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { GlobalContext } from '../context';
import { changeTheme } from '../context/actions';
import { darkColors, theme } from '../components/Theme';
import { saveTheme } from '../utils/helpers';

export default function useThemeSwitch() {
  const {
    state: { themeMode },
    dispatch,
  } = useContext(GlobalContext);

  const { i18n } = useTranslation();

  const toggleTheme = useCallback(() => {
    const newThemeMode = themeMode === 'dark' ? 'light' : 'dark';
    dispatch(changeTheme(newThemeMode));
    saveTheme(newThemeMode);
  }, [themeMode]);

  const selectedTheme = useMemo(
    () =>
      themeMode === 'dark'
        ? {
            ...theme,
            colors: darkColors,
            mode: themeMode,
            rtl: i18n.language === 'ar',
          }
        : { ...theme, mode: themeMode, rtl: i18n.language === 'ar' },
    [themeMode, i18n.language],
  );

  // console.log(
  //   `%c${themeMode}`,
  //   themeMode === 'light'
  //     ? 'background-color:orange;color:white;font-size:28px;'
  //     : 'background-color:black;color:orange;font-size:28px;',
  // );

  return useMemo(
    () => ({ toggleTheme, selectedTheme, themeMode }),
    [toggleTheme, selectedTheme, themeMode],
  );
}
