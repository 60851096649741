import {
  Action,
  carRegistrationType,
  ClientPayload,
  contactInfoType,
  nameInfoType,
  themeType,
} from './types';
import {
  CHANGE_THEME,
  DOWNLOAD_RECEIPT,
  DOWNLOAD_RECEIPT_FAILED,
  DOWNLOAD_RECEIPT_SUCCESS,
  GET_CMI_PROPS,
  GET_CMI_PROPS_ERROR,
  GET_CMI_PROPS_SUCCESS,
  GET_HASH,
  GET_HASH_FAILED,
  GET_HASH_SUCCESS,
  GET_UNPAID_TAXES,
  GET_UNPAID_TAXES_FAILED,
  GET_UNPAID_TAXES_SUCCESS,
  NEXT_STEP,
  PREVIOUS_STEP,
  RESET_STEPS,
  SAVE_CLIENT,
  SAVE_CLIENT_FAILED,
  SAVE_CLIENT_SUCCESS,
  SET_CAR_REGISTRATION_VALUES,
  SET_CONTACT_INFO,
  SET_HAS_AGREED_ON_TERMS,
  SET_NAME_INFO,
  SET_PAYMENT_RESPONSE,
  SET_PAYMENT_RESULT_DATA,
  SET_PHONE_COUNTRY,
  SET_RECAPTCHA,
  TOGGLE_LOADING,
  UPDATE_CLIENT,
  UPDATE_CLIENT_FAILED,
  UPDATE_CLIENT_SUCCESS,
} from './constants';

export function changeTheme(themeMode: themeType): Action {
  return {
    type: CHANGE_THEME,
    payload: themeMode,
  };
}

export function setNameInfo(nameInfoValues: nameInfoType): Action {
  return {
    type: SET_NAME_INFO,
    payload: nameInfoValues,
  };
}

export function setContactInfo(contactInfoValues: contactInfoType): Action {
  return {
    type: SET_CONTACT_INFO,
    payload: contactInfoValues,
  };
}

export function setCarRegistrationValues(
  carRegistrationValues: carRegistrationType,
): Action {
  return {
    type: SET_CAR_REGISTRATION_VALUES,
    payload: carRegistrationValues,
  };
}

export function nextStep(): Action {
  return {
    type: NEXT_STEP,
  };
}

export function previousStep(): Action {
  return {
    type: PREVIOUS_STEP,
  };
}

export function resetSteps(): Action {
  return {
    type: RESET_STEPS,
  };
}

export function getCmiProps(): Action {
  return {
    type: GET_CMI_PROPS,
  };
}

export function getCmiPropsSuccess(data: any): Action {
  return {
    type: GET_CMI_PROPS_SUCCESS,
    payload: data,
  };
}

export function getCmiPropsFailed(error: string): Action {
  return {
    type: GET_CMI_PROPS_ERROR,
    payload: error,
  };
}

export function setRecaptcha(token: string): Action {
  return {
    type: SET_RECAPTCHA,
    payload: token,
  };
}

export function setHasAgreedOnTerms(checked: boolean): Action {
  return {
    type: SET_HAS_AGREED_ON_TERMS,
    payload: checked,
  };
}

export function saveClientRequest(): Action {
  return {
    type: SAVE_CLIENT,
  };
}

export function saveClientSuccess(clientData: ClientPayload): Action {
  return {
    type: SAVE_CLIENT_SUCCESS,
    payload: clientData,
  };
}

export function saveClientFailed(error: string): Action {
  return {
    type: SAVE_CLIENT_FAILED,
    payload: error,
  };
}

export function getHashRequest(): Action {
  return {
    type: GET_HASH,
  };
}

export function getHashSuccess(hash: string): Action {
  return {
    type: GET_HASH_SUCCESS,
    payload: hash,
  };
}

export function getHashFailed(error: string): Action {
  return {
    type: GET_HASH_FAILED,
    payload: error,
  };
}

export function getUnpaidTaxesRequest(): Action {
  return {
    type: GET_UNPAID_TAXES,
  };
}

export function getUnpaidTaxesSuccess(data: any): Action {
  return {
    type: GET_UNPAID_TAXES_SUCCESS,
    payload: data,
  };
}

export function getUnpaidTaxesFailed(error: string): Action {
  return {
    type: GET_UNPAID_TAXES_FAILED,
    payload: error,
  };
}

export function toggleLoading(): Action {
  return {
    type: TOGGLE_LOADING,
  };
}

export function downloadReceiptRequest(): Action {
  return {
    type: DOWNLOAD_RECEIPT,
  };
}

export function downloadReceiptSuccess(data: string): Action {
  return {
    type: DOWNLOAD_RECEIPT_SUCCESS,
    payload: data,
  };
}

export function downloadReceiptFailed(error: string): Action {
  return {
    type: DOWNLOAD_RECEIPT_FAILED,
    payload: error,
  };
}

export function updateClientRequest(): Action {
  return {
    type: UPDATE_CLIENT,
  };
}

export function updateClientSuccess(clientData: ClientPayload): Action {
  return {
    type: UPDATE_CLIENT_SUCCESS,
    payload: clientData,
  };
}

export function updateClientFailed(error: string): Action {
  return {
    type: UPDATE_CLIENT_FAILED,
    payload: error,
  };
}

export function setPaymentResponse(response: object): Action {
  return {
    type: SET_PAYMENT_RESPONSE,
    payload: response,
  };
}

export function setPaymentResultData(data: object): Action {
  return {
    type: SET_PAYMENT_RESULT_DATA,
    payload: data,
  };
}

export function setPhoneCountry(country: string): Action {
  return {
    type: SET_PHONE_COUNTRY,
    payload: country,
  };
}
