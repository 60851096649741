import memoize from 'lodash.memoize';
import MA from '../../images/flags/MA.svg';
import BE from '../../images/flags/BE.svg';
import ES from '../../images/flags/ES.svg';
import FR from '../../images/flags/FR.svg';
import IT from '../../images/flags/IT.svg';
import NL from '../../images/flags/NL.svg';
import DE from '../../images/flags/DE.svg';
import GLOBE from '../../images/flags/GLOBE.svg';

export const countryPhones: any = {
  MA: {
    regexValidPhone: '^(6|7|5)[0-9]{8}',
    identifier: '+212 ',
    format: '+212 #########',
    label: 'Maroc',
    value: 'MA',
    flag: MA,
  },
  FR: {
    regexValidPhone: '^(6|7)[0-9]{8}',
    identifier: '+33 ',
    format: '+33 #########',
    label: 'France',
    value: 'FR',
    flag: FR,
  },
  ES: {
    regexValidPhone: '^(6|7)[0-9]{8}',
    identifier: '+34 ',
    format: '+34 #########',
    label: 'ESPAGNE',
    value: 'ES',
    flag: ES,
  },
  IT: {
    regexValidPhone: '[0-9]{8}',
    identifier: '+39 ',
    format: '+39 ##########',
    label: 'ITALIE',
    value: 'IT',
    flag: IT,
  },
  BE: {
    regexValidPhone: '[0-9]{8}',
    identifier: '+32 ',
    format: '+32 ##########',
    label: 'BELGIQUE',
    value: 'BE',
    flag: BE,
  },
  NL: {
    regexValidPhone: '[0-9]{8}',
    identifier: '+31 ',
    format: '+31 ###########',
    label: 'Pays-Bas',
    value: 'NL',
    flag: NL,
  },
  DE: {
    regexValidPhone: '[0-9]{8}',
    identifier: '+49 ',
    format: '+49 ##########',
    label: 'Allemagne',
    value: 'DE',
    flag: DE,
  },
  GLOBE: {
    regexValidPhone: '\\d{4}|\\d{13}',
    identifier: '+ ',
    format: '+#############',
    label: 'Autre',
    value: 'GLOBE',
    flag: GLOBE,
  },
};

export const countryIdentifiers = Object.values(countryPhones).map(
  ({ identifier, value, label }: any) => ({
    identifier: identifier.replace(/([()])|0|\s/g, ''),
    value,
    label,
  }),
);

export const countriesList = [
  {
    label: 'Maroc',
    value: 'MA',
  },
  {
    label: 'France',
    value: 'FR',
  },
  {
    label: 'ESPAGNE',
    value: 'ES',
  },
  {
    label: 'ITALIE',
    value: 'IT',
  },
  {
    label: 'BELGIQUE',
    value: 'BE',
  },
  {
    label: 'Pays-Bas',
    value: 'NL',
  },
  {
    label: 'Allemagne',
    value: 'DE',
  },
  {
    label: 'Autre',
    value: 'GLOBE',
  },
];

const getFlagStyle = memoize((country: string) => ({
  alignItems: 'center',
  display: 'flex',
  ':before': {
    backgroundImage:
      // eslint-disable-next-line global-require,import/no-dynamic-require
      country && `url(${countryPhones[country].flag})`,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 30,
    width: 50,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    borderRadius: 5,
  },
}));

export const flagStyles = {
  input: (styles: any) => ({
    ...styles,
    outline: 'none',
  }),
  placeholder: (styles: any) => ({ ...styles, outline: 'none' }),
  singleValue: (styles: any, { data }: any) => ({
    ...styles,
    ...getFlagStyle(data.value),
    outline: 'none',
  }),
  option: (styles: any) => ({ ...styles, fontSize: '14px' }),
  control: (styles: any) => ({
    ...styles,
    border: 0,
    // This line disable the blue border
    boxShadow: 'none',
  }),
};
