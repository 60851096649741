export const CHANGE_THEME = 'CHANGE_THEME';
export const DEFAULT_ACTION = 'DEFAULT_ACTION';

export const SET_CAR_REGISTRATION_VALUES = 'SET_CAR_REGISTRATION_VALUES';

export const SET_NAME_INFO = 'SET_NAME_INFO';

export const SET_CONTACT_INFO = 'SET_CONTACT_INFO';

export const SET_RECAPTCHA = 'SET_RECAPTCHA';
export const SET_HAS_AGREED_ON_TERMS = 'SET_HAS_AGREED_ON_TERMS';

export const NEXT_STEP = 'NEXT_STEP';
export const PREVIOUS_STEP = 'PREVIOUS_STEP';

export const RESET_STEPS = 'RESET_STEPS';

export const GET_CMI_PROPS = 'GET_CMI_PROPS';
export const GET_CMI_PROPS_SUCCESS = 'GET_CMI_PROPS_SUCCESS';
export const GET_CMI_PROPS_ERROR = 'GET_CMI_PROPS_ERROR';

export const SAVE_CLIENT = 'SAVE_CLIENT';
export const SAVE_CLIENT_SUCCESS = 'SAVE_CLIENT_SUCCESS';
export const SAVE_CLIENT_FAILED = 'SAVE_CLIENT_FAILED';

export const GET_UNPAID_TAXES = 'GET_UNPAID_TAXES';
export const GET_UNPAID_TAXES_SUCCESS = 'GET_UNPAID_TAXES_SUCCESS';
export const GET_UNPAID_TAXES_FAILED = 'GET_UNPAID_TAXES_FAILED';

export const GET_HASH = 'GET_HASH';
export const GET_HASH_SUCCESS = 'GET_HASH_SUCCESS';
export const GET_HASH_FAILED = 'GET_HASH_FAILED';

export const TOGGLE_LOADING = 'TOGGLE_LOADING';

export const DOWNLOAD_RECEIPT = 'DOWNLOAD_RECEIPT';
export const DOWNLOAD_RECEIPT_SUCCESS = 'DOWNLOAD_RECEIPT_SUCCESS';
export const DOWNLOAD_RECEIPT_FAILED = 'DOWNLOAD_RECEIPT_FAILED';

export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT_FAILED = 'UPDATE_CLIENT_FAILED';

export const SET_PAYMENT_RESPONSE = 'SET_PAYMENT_RESPONSE';
export const SET_PAYMENT_RESULT_DATA = 'SET_PAYMENT_RESULT_DATA';

export const SET_PHONE_COUNTRY = 'SET_PHONE_COUNTRY';
