export const SECTIONS_PATHS = {
  PAYMENT: 'payment',
  HOW_IT_WORKS: 'how-it-works',
  RAFFLE: 'raffle',
  FAQ: 'faq',
  CONTACT: 'contact',
  TERMS: 'terms-and-conditions',
  LEGAL_NOTICE: 'legal-notice',
};

export const CAR_PLATES_LETTERS = [
  {
    value: 'A',
    label: 'A - أ',
  },
  {
    value: 'B',
    label: 'B - ب',
  },
  {
    value: 'D',
    label: 'D - د',
  },
  {
    value: 'H',
    label: 'H - ﻫ',
  },
  {
    value: 'E',
    label: 'E - و',
  },
  {
    value: 'T',
    label: 'T - ط',
  },
  {
    value: 'CC',
    label: 'CC',
  },
  {
    value: 'CI',
    label: 'CI',
  },
  {
    value: 'OI',
    label: 'OI',
  },
  {
    value: 'CD',
    label: 'CD',
  },
  {
    value: 'CMD',
    label: 'CMD',
  },
  {
    value: 'PAT',
    label: 'PAT',
  },
  {
    value: 'M',
    label: 'M',
  },
];

export const FUEL_OPTIONS = [
  {
    label: 'DIESEL',
    value: 'DIESEL',
  },
  {
    label: 'ELECTRIQUE',
    value: 'ELECTRIQUE',
  },
  {
    label: 'ESSENCE',
    value: 'ESSENCE',
  },
  {
    label: 'GPL',
    value: 'GPL',
  },
  {
    label: 'HYBRIDE',
    value: 'HYBRID',
  },
];

export const YEAR_OPTIONS = [
  {
    label: '2024',
    value: '2024',
  },
  {
    label: '2023',
    value: '2023',
  },
  {
    label: '2022',
    value: '2022',
  },
  {
    label: '2021',
    value: '2021',
  },
  {
    label: '2020',
    value: '2020',
  },
  {
    label: '2019',
    value: '2019',
  },
  {
    label: '2018',
    value: '2018',
  },
  {
    label: '2017',
    value: '2017',
  },
  {
    label: '2016',
    value: '2016',
  },
];

export const FIELD_NAMES = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL_ADDRESS: 'email',
  CONFIRMED_EMAIL_ADDRESS: 'confirmedEmail',
  PHONE_NUMBER: 'phoneNumber',
  CAR_REGISTRATION_1: 'carRegistrationChunk1',
  CAR_REGISTRATION_2: 'carRegistrationChunk2',
  CAR_REGISTRATION_3: 'carRegistrationChunk3',
  FISCAL_HORSE_POWER: 'fiscalHorsePower',
  FUEL: 'fuel',
  YEAR: 'year',
  RECAPTCHA: 'recaptcha',
  ACCEPTED_TERMS: 'hasAgreedOnTerms',
};

export const FORM_STEPS = {
  FULL_NAME: 'FULL_NAME',
  CONTACT_INFO: 'CONTACT_INFO',
  CAR_REGISTRATION: 'CAR_REGISTRATION',
  RECAP: 'RECAP',
};

export const SPECIAL_CODES = {
  CODE_CREANCIER: '1022',
  CODE_CREANCE: '01',
};

export const FAQS = [
  {
    title: 'COMMENT PAYER MA VIGNETTE SUR LE SITE VIGNETTE.SGMAROC.COM ?',
    description: `
      <p>
        Cette plateforme vous permet de régler votre vignette en 2 minutes !
      </p>
      <ol>
        <li>
          Vous remplissez le formulaire en saisissant les informations figurant sur votre carte grise.
        </li>
        <li>
          Vous validez le montant affiché à l’écran.
        </li>
        <li>
          Vous saisissez les coordonnées de votre carte de paiement ainsi que votre code sécurisé d’authentification en ligne que vous recevez par SMS.
        </li>
        <li>
          Vous imprimez votre reçu de paiement : il vous sera envoyé par email après confirmation du paiement.
        </li>
        <li>
          Editez votre quittance sur le site de la DGI à travers le raccourci proposé.
        </li>
      </ol>
    `,
  },
  {
    title: 'COMMENT VÉRIFIER LE SUCCÈS DE MON PAIEMENT ?',
    description: `
        <p>Après validation du paiement, les informations sont transmises au Centre Monétique Interbancaire pour traitement.</p>
        <p>Vous recevez le résultat sur votre écran : paiement accepté ou refusé. Un e-mail de confirmation du paiement vous sera envoyé dès acceptation.</p>
    `,
  },
  {
    title: 'QU’EN EST- IL DU MACARON DE LA VIGNETTE ?',
    description: `
        <p>La remise du macaron de la vignette est abolie, et l’ancien reçu a été changé contre un nouveau informatisé. Le paiement de cette taxe est dorénavant attesté par la seule remise d’une quittance (reçu) de paiement à présenter à l’agent de police lors du contrôle.</p>
      `,
  },
  {
    title: 'QUE FAIRE EN CAS DE PERTE DE MA QUITTANCE DE PAIEMENT ?',
    description: `
      <p>En cas de perte de votre reçu, vous pourrez réimprimer à tout moment une attestation de paiement de votre vignette sur le site de la DGI (Direction Générale des Impôts) <a target="blank" href="https://www.vignette.ma"> vignette.ma </a></p>
      `,
  },
  {
    title:
      'COMMENT PUIS-JE ÊTRE SÛR DE LA CONFIDENTIALITÉ DE MES DONNÉES PERSONNELLES ?',
    description: `
        <p>La Banque prend les mesures physiques, techniques et organisationnelles appropriées pour assurer la sécurité et la confidentialité des données à caractère personnel, en vue notamment de les protéger contre toute perte, destruction accidentelle, altération et accès non autorisés.</p>
      `,
  },
  {
    title:
      'QUELLE EST LA COMMISSION DU SERVICE DE PAIEMENT DE MA VIGNETTE SUR LE SITE VIGNETTE.SGMAROC.COM ?',
    description: `<p>
      Le coût du service de paiement en ligne de la Vignette est fixé à 12 DH TTC. Il est indiqué clairement sur votre reçu de paiement.
      </p>`,
  },
  {
    title: 'QUEL EST LE MONTANT DE MA VIGNETTE QUE JE DOIS PAYER ?',
    description: `
      <p>
      Le montant que vous devez payer en fonction de la puissance fiscale et la catégorie de votre véhicule ?
      </p>
      <table>
        <thead>
          <tr>
            <th>PUISSANCE FISCALE</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>Catégorie</th>
            <th> &lt;8 cv</th>
            <th>de 8 à 10 cv</th>
            <th>de 11 à 14 cv</th>
            <th>≥ 15 cv</th>
          </tr>
          <tr>
            <th>Essence</th>
            <th>350 Dh</th>
            <th>650 Dh</th>
            <th>3000 Dh</th>
            <th>8000 Dh</th>
          </tr>
          <tr>
            <th>Gasoil</th>
            <th>700 Dh</th>
            <th>1500 Dh</th>
            <th>6000 Dh</th>
            <th>20 000 Dh</th>
          </tr>
        </tbody>
      </table>
      `,
  },
  {
    title: 'QUI CONTACTER EN CAS DE PROBLÈME ?',
    description: `
      <p>
        Pour toutes informations ou questions concernant le paiement via le site vignette.sgmaroc.com, vous pouvez appeler Sogetel, notre service relation clients au 0522434242.
      </p>
      `,
  },
];
