import { createContext, Dispatch, ReactNode, useMemo, useReducer } from 'react';
import { globalReducer, INITIAL_STATE } from './reducer';
import { Action, INITIAL_STATE_TYPE } from './types';

const GlobalContext = createContext<{
  state: INITIAL_STATE_TYPE;
  dispatch: Dispatch<Action>;
}>({
  state: INITIAL_STATE,
  dispatch: () => null,
});

interface GlobalProviderProps {
  children: ReactNode;
}

function GlobalProvider({ children }: GlobalProviderProps) {
  const [state, dispatch] = useReducer(globalReducer, INITIAL_STATE);

  const value = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  return (
    <GlobalContext.Provider value={value}>{children}</GlobalContext.Provider>
  );
}

export { GlobalProvider, GlobalContext };
