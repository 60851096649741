import produce from 'immer';
import { getThemeFromStorage } from '../utils/helpers';
import * as actions from './constants';
import { INITIAL_STATE_TYPE } from './types';
import {
  CAR_PLATES_LETTERS,
  FIELD_NAMES,
  FUEL_OPTIONS,
  YEAR_OPTIONS,
} from '../utils/data';
import { countriesList } from '../components/PhoneInput/helpers';

export const INITIAL_STATE: INITIAL_STATE_TYPE = {
  step: 1,
  themeMode: getThemeFromStorage() || 'light',
  carRegistration: {
    [FIELD_NAMES.CAR_REGISTRATION_1]: '',
    [FIELD_NAMES.CAR_REGISTRATION_2]: CAR_PLATES_LETTERS[0].value,
    [FIELD_NAMES.CAR_REGISTRATION_3]: '',
    [FIELD_NAMES.FISCAL_HORSE_POWER]: '',
    [FIELD_NAMES.FUEL]: FUEL_OPTIONS[0].value,
    [FIELD_NAMES.YEAR]: YEAR_OPTIONS[0].value,
  },
  nameInfo: {
    [FIELD_NAMES.FIRST_NAME]: '',
    [FIELD_NAMES.LAST_NAME]: '',
  },
  contactInfo: {
    [FIELD_NAMES.EMAIL_ADDRESS]: '',
    [FIELD_NAMES.CONFIRMED_EMAIL_ADDRESS]: '',
    [FIELD_NAMES.PHONE_NUMBER]: '',
  },
  recaptcha: '',
  hasAgreedOnTerms: false,
  loading: false,
  error: null,
  cmiProps: null,
  clientData: null,
  hashData: '',
  unpaidTaxes: null,
  receipt: null,
  paymentResponse: null,
  paymentResultData: null,
  extraClientPaymentData: null,
  phoneCountry: countriesList[0].value,
};

// eslint-disable-next-line consistent-return
export const globalReducer = produce((draft, { type, payload }) => {
  switch (type) {
    case actions.CHANGE_THEME:
      draft.themeMode = payload;
      break;
    case actions.SET_CAR_REGISTRATION_VALUES:
      draft.carRegistration = { ...draft.carRegistration, ...payload };
      break;
    case actions.SET_NAME_INFO:
      draft.nameInfo = payload;
      break;

    case actions.SET_CONTACT_INFO:
      draft.contactInfo = payload;
      break;

    case actions.NEXT_STEP:
      draft.step += 1;
      break;

    case actions.PREVIOUS_STEP:
      draft.step -= 1;
      break;

    case actions.UPDATE_CLIENT:
    case actions.DOWNLOAD_RECEIPT:
    case actions.GET_UNPAID_TAXES:
    case actions.GET_HASH:
    case actions.SAVE_CLIENT:
    case actions.GET_CMI_PROPS:
      draft.loading = true;
      draft.error = null;
      break;

    case actions.GET_CMI_PROPS_SUCCESS:
      draft.loading = false;
      draft.cmiProps = payload;
      break;
    case actions.UPDATE_CLIENT_FAILED:
    case actions.DOWNLOAD_RECEIPT_FAILED:
    case actions.GET_UNPAID_TAXES_FAILED:
    case actions.GET_HASH_FAILED:
    case actions.SAVE_CLIENT_FAILED:
    case actions.GET_CMI_PROPS_ERROR:
      draft.loading = false;
      draft.error = payload;
      break;
    case actions.UPDATE_CLIENT_SUCCESS:
    case actions.SAVE_CLIENT_SUCCESS:
      draft.loading = false;
      draft.clientData = payload;
      break;

    case actions.GET_HASH_SUCCESS:
      draft.loading = false;
      draft.hashData = payload;
      break;

    case actions.GET_UNPAID_TAXES_SUCCESS:
      draft.loading = false;
      draft.unpaidTaxes = payload;
      break;

    case actions.SET_RECAPTCHA:
      draft.recaptcha = payload;
      break;

    case actions.SET_HAS_AGREED_ON_TERMS:
      draft.hasAgreedOnTerms = payload;
      break;
    case actions.TOGGLE_LOADING:
      draft.loading = !draft.loading;
      break;

    case actions.DOWNLOAD_RECEIPT_SUCCESS:
      draft.loading = false;
      draft.receipt = payload;
      break;

    case actions.SET_PAYMENT_RESPONSE:
      draft.paymentResponse = payload;
      break;

    case actions.SET_PAYMENT_RESULT_DATA:
      draft.paymentResultData = payload.data;
      draft.extraClientPaymentData = payload.extra;
      break;

    case actions.SET_PHONE_COUNTRY:
      draft.phoneCountry = payload;
      break;

    case actions.RESET_STEPS:
      // eslint-disable-next-line no-param-reassign
      return { ...INITIAL_STATE, themeMode: draft.themeMode };

    default:
      break;
  }
}, INITIAL_STATE);
