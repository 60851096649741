// eslint-disable-next-line no-use-before-define
import React from 'react';
import { Helmet } from 'react-helmet';
import { ToastContainer } from 'react-toastify';
import { enableAllPlugins } from 'immer';
import { GlobalProvider } from './src/context';
import Theme from './src/components/Theme';
import 'intersection-observer';
// Initialize languages
import './src/locales/i18n';
import 'react-toastify/dist/ReactToastify.css';

enableAllPlugins();

// console.log({ ENV: process.env.NODE_ENV });

if (process.env.NODE_ENV === 'production') {
  console.log = () => {};
}

// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }) => (
  <>
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700;800&display=swap"
        rel="stylesheet"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300;400;700&display=swap"
        rel="stylesheet"
      />
    </Helmet>
    <GlobalProvider>
      <Theme>
        <ToastContainer />
        {element}
      </Theme>
    </GlobalProvider>
  </>
);
