/* istanbul ignore next line */
import { carRegistrationType, themeType } from '../context/types';
import { countryPhones } from '../components/PhoneInput/helpers';

export const windowGlobal = typeof window !== 'undefined' && window;

export const isSystemDark = () =>
  // eslint-disable-next-line no-nested-ternary
  windowGlobal
    ? window?.matchMedia
      ? window.matchMedia('(prefers-color-scheme: dark)')?.matches
      : undefined
    : undefined;

export function saveTheme(theme: themeType) {
  // eslint-disable-next-line no-unused-expressions
  window.localStorage && localStorage.setItem('selectedTheme', theme);
}

export function getThemeFromStorage(): themeType | null {
  return windowGlobal
    ? (localStorage.getItem('selectedTheme') as themeType)
    : null;
}

export const isEmpty = (obj: object) => Object.keys(obj).length === 0;

export const makePlateNumber = ({
  carRegistrationChunk1,
  carRegistrationChunk2,
  carRegistrationChunk3,
}: carRegistrationType) =>
  `${carRegistrationChunk1}${carRegistrationChunk2}${carRegistrationChunk3}`.replace(
    /\s/g,
    '',
  );

export const isJSON = (str: string) => {
  try {
    return JSON.parse(str) && !!str;
  } catch (e) {
    return false;
  }
};

export function openFileInANewTab(url: string) {
  const a = document.createElement('a');
  a.href = url;
  // a.download = `${title} ${new Date().toLocaleDateString('en-gb')}.pdf`;
  a.target = '_blank';
  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click();
  a.remove(); // afterwards we remove the element again
  // URL.revokeObjectURL(url);
}

export function downloadFile(url: string, title: string) {
  const a = document.createElement('a');
  a.href = url;
  a.download = `${title} ${new Date().toLocaleDateString('en-gb')}.pdf`;
  document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
  a.click();
  a.remove(); // afterwards we remove the element again
  // URL.revokeObjectURL(url);
}

/* const phoneRegx = new RegExp('^(6|7|5)[0-9]{8}');
export function validatePhoneNumber(v: string): boolean {
  const valueToValidate = v && v.replace('+212 (0)', '');
  if (valueToValidate) {
    const phoneNumberToValidate = valueToValidate.replace(/ /g, '');
    // console.log({ phoneNumberToValidate });
    return phoneRegx.test(phoneNumberToValidate);
  }
  return false;
} */

export const validatePhoneNumber = (country: string, v: string) => {
  const valueToValidate =
    v && v.replace(countryPhones[country].identifier, '').trim();
  // console.log({ country, phoneNumber: v, valueToValidate });

  if (valueToValidate) {
    const phoneRegx = new RegExp(countryPhones[country].regexValidPhone);
    // console.log({ phoneRegx });
    const phoneNumberToValidate = valueToValidate.replace(/ /g, '');
    return phoneRegx.test(phoneNumberToValidate);
  }
  return false;
};

export function getTaxDescriptionElements(description: string) {
  return description.split('<br/>').map(e => {
    const field = e.split(':');
    return {
      title: field[0],
      amount: field[1],
    };
  });
}

export const loadClientStateData = () => {
  try {
    const serializedState = localStorage.getItem('clientData');
    return serializedState === null ? undefined : JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveClientStateData = (state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('clientData', serializedState);
  } catch (err) {
    console.log('failed while saving state to session storage', err);
  }
};

export const clearClientData = () => {
  localStorage.removeItem('clientData');
};
