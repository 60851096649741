import { memo, ReactNode, FunctionComponent } from 'react';
import {
  ThemeProvider,
  createGlobalStyle,
  DefaultTheme,
} from 'styled-components';
import useThemeSwitch from '../../hooks/useThemeSwitch';

/* import { GlobalContext } from '../../context';
import { isSystemDark } from '../../utils/helpers'; */

const lightColors = {
  primary: '#ef3b46',
  secondary: '#3f3d56',
  mainBackground: '#fff',
  white: '#fff',
  grayBlue: '#eef2f5',
  disabledGray: '#c1c1c1',
  lighterGray: '#fafafa',
  secondaryBackground: '#e6f0f5',
  accordionBackground: '#f7f7f7',
  shadow: '#ebebeb',
  danger: '#ff0010',
  lightGray: '#f2f2f2',
};

export const darkColors = {
  primary: '#ef3b46',
  secondary: '#fff',
  mainBackground: '#3f3d56',
  white: '#fff',
  grayBlue: '#eef2f5',
  disabledGray: '#c1c1c1',
  lighterGray: 'transparent',
  secondaryBackground: '#565370a6',
  accordionBackground: '#3f3d56',
  shadow: '#2f2e40',
  danger: '#ff7e95',
  lightGray: '#f2f2f2',
};

export interface ThemeInterface extends DefaultTheme {
  colors: {
    primary: string;
    secondary: string;
    mainBackground: string;
    white: string;
    grayBlue: string;
    disabledGray: string;
  };
  fonts: {
    Montserrat: string;
    SourceSansPro: string;
  };
  fontWeights: {
    Montserrat: {
      regular: number;
      semiBold: number;
      bold: number;
      extraBold: number;
    };
    SourceSansPro: {
      light: number;
      regular: number;
      bold: number;
    };
  };
  rtl: boolean;
}

export const theme: ThemeInterface = {
  colors: lightColors,
  fonts: {
    Montserrat: 'Montserrat',
    SourceSansPro: 'Source Sans Pro',
  },
  fontWeights: {
    Montserrat: {
      regular: 400,
      semiBold: 600,
      bold: 700,
      extraBold: 800,
    },
    SourceSansPro: {
      light: 300,
      regular: 400,
      bold: 700,
    },
  },
  rtl: false,
};

const GlobalStyle = createGlobalStyle<{ myTheme?: ThemeInterface }>`
    
    /* http://meyerweb.com/eric/tools/css/reset/
    v5.0.1 | 20191019
    License: none (public domain)
    */
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, menu, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    main, menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font: inherit;
        vertical-align: baseline;
    }
    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure,
    footer, header, hgroup, main, menu, nav, section {
        display: block;
    }
    /* HTML5 hidden-attribute fix for newer browsers */
    *[hidden] {
        display: none;
    }
    body {
        line-height: 1;
    }
    menu, ol, ul {
        list-style: none;
    }
    blockquote, q {
        quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    *{
        box-sizing: border-box;
    }
    html {
        overflow-x: hidden;
        scroll-behavior: smooth;
        direction: ${props => (props.myTheme?.rtl ? 'rtl' : 'ltr')}
    }

    body h1,
    body h2,
    body h3,
    body h4,
    body h5,
    body h6 {
        margin: 0;
    }

    body p {
        margin: 0;
    }

    body ul,
    body li {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    body img {
        display: block;
        max-width: 100%;
    }
    body a,body button,body input,body textarea{
        outline: none;
    }

    body,
    body * {
        box-sizing: border-box;
    }
    body {
        font-family: "Source Sans Pro", sans-serif;
        font-size: 14px;
        color: ${props => props.myTheme?.colors.secondary};
        background: ${props => props.myTheme?.colors.mainBackground};
        transition: background-color 0.5s ease;
        /* overflow-x: hidden; */
    }
   
   
   a{
    color : ${props => props.myTheme?.colors.secondary};
   }
    
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    
    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
    
      .animatedSlide {
        width : 100%;
    }

    .fadeSlideTransition-enter .animatedSlide {
    opacity: 0;
    transform: translateX(200px);
    }
    .fadeSlideTransition-enter-active .animatedSlide {
        opacity: 1;
        transform: translateX(0%);
    }
    .fadeSlideTransition-exit .animatedSlide {
        opacity: 1;
        transform: translateX(0%);
    }
    .fadeSlideTransition-exit-active .animatedSlide {
        opacity: 0;
        transform: translateX(-200px);
    }
    .fadeSlideTransition-enter-active .animatedSlide {
        transition: opacity 0.1s, transform 0.1s;
    }
    .fadeSlideTransition-exit-active .animatedSlide {
        transition: opacity 0.1s, transform 0.1s;
    }
`;

const Theme: FunctionComponent<ReactNode> = ({ children }) => {
  // TODO: fix theme issue
  const { selectedTheme } = useThemeSwitch();

  // console.log({ selectedTheme: selectedTheme.mode });

  /*
  console.log(
    `%c${selectedTheme.mode} THEME PROVIDER`,
    selectedTheme.mode === 'light'
      ? 'background-color:orange;color:white;font-size:28px;'
      : 'background-color:black;color:orange;font-size:28px;',
  );
*/

  return (
    <ThemeProvider theme={selectedTheme}>
      <GlobalStyle myTheme={selectedTheme} />
      {children}
    </ThemeProvider>
  );
};
export default memo(Theme);
